
























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import {
  ADD_LETTER,
  FETCH_LETTER_PDF,
  FETCH_LETTER_PREVIEW,
  FETCH_LETTER_WORD,
  FETCH_RENDERED_LETTER,
  GET_LETTER_ACTIVE,
  INIT_LETTER_ACTIVE,
  SET_LETTER_ACTIVE
} from '../store';
import RichEditor from '../components/RichEditor.vue';
import { ReceiverType, RenderLetterParams, WriteLetter } from '../types';
import { ApiResponse } from '@/components/types';
import ViewDialog from '@/modules/correspondence/components/ViewDialog.vue';
import DatePicker from '@/components/form/DatePicker.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: { DatePicker, ViewDialog, RichEditor }
})
export default class CreateLetter extends Vue {
  @Correspondence.Getter(GET_LETTER_ACTIVE) active!: WriteLetter | null;
  @Correspondence.Mutation(SET_LETTER_ACTIVE) setActive!: (letter: WriteLetter | null) => void;
  @Correspondence.Mutation(INIT_LETTER_ACTIVE) initActive!: (employee: string) => void;
  @Correspondence.Action(FETCH_RENDERED_LETTER) fetchRendered!: (params: RenderLetterParams) => Promise<ApiResponse>;
  @Correspondence.Action(ADD_LETTER) addLetter!: (letter: WriteLetter) => Promise<ApiResponse>;
  @Correspondence.Action(FETCH_LETTER_PDF) fetchPdf!: (letterId: string) => Promise<ApiResponse>;
  @Correspondence.Action(FETCH_LETTER_PREVIEW) fetchPreview!: (letter: WriteLetter) => Promise<ApiResponse>;
  @Correspondence.Action(FETCH_LETTER_WORD) fetchWord!: (letter: WriteLetter) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  loadingPreview: boolean = false;
  loadingWord: boolean = false;
  disabled: boolean = false;
  dialog: boolean = false;
  url: string | null = null;
  view: Blob | null = null;
  wordUrl: string | null = null;

  get conservatorship(): string {
    return this.$route.query['conservatorship'] as string;
  }

  get financeAccount(): string {
    return this.$route.query['financeAccount'] as string;
  }

  get contact(): any {
    return this.$route.query['contact'];
  }

  get contactPerson(): string {
    return this.$route.query['contactPerson'] as string;
  }

  get receiverType(): ReceiverType {
    return this.$route.params['receiverType'] as ReceiverType;
  }

  get template(): string {
    return this.$route.params['template'];
  }

  get filename(): string {
    if (!this.letter) return 'letter';

    const today = (new Date()).toISOString().split('T')[0];

    return `${slugify(`${this.letter.receiver.name}-${today}`)}`;
  }

  get letter(): WriteLetter | null {
    return this.active;
  }

  set letter(letter: WriteLetter | null) {
  }

  @Watch('letter', { deep: true })
  changeLetter(letter: WriteLetter | null) {
    this.setActive(letter);
  }

  close() {
    setTimeout(() => {
      if (this.url) URL.revokeObjectURL(this.url);

      if (this.$route.query['referrer']) {
        this.$router.push(this.$route.query['referrer'] as string).catch(() => {
        });

        return;
      }

      this.$router.back();
    }, 500);
  }

  async save() {
    if (this.view) {
      this.view = null;
    }

    this.error = null;

    if (!this.letter) return {};

    const { error } = await this.addLetter(this.letter);

    if (error) {
      return { error };
    }

    return this.fetchPdf(this.letter.letter_id);
  }

  async onSuccess(content: Blob) {
    this.view = content;
    this.disabled = false;
  }

  async preview() {
    if (!this.letter) return;

    this.loadingPreview = true;
    this.view = null;

    const { error, content } = await this.fetchPreview(this.letter);

    if (error) {
      this.error = error;
      this.loadingPreview = false;

      return;
    }

    this.view = content;
    this.loadingPreview = false;
  }

  async wordDocument() {
    if (!this.letter) return;

    if (this.wordUrl) {
      URL.revokeObjectURL(this.wordUrl);
    }

    this.loadingWord = true;
    this.wordUrl = null;

    const { error, content } = await this.fetchWord(this.letter as WriteLetter);

    if (error) {
      this.error = error;
      this.loadingWord = false;

      return;
    }

    this.wordUrl = URL.createObjectURL(content);
    this.loadingWord = false;
  }

  async created() {
    this.initActive(this.$auth.user().id);

    const { error } = await this.fetchRendered({
      template: this.template,
      conservatorship: this.conservatorship,
      contact: this.contact,
      contact_person: this.contactPerson,
      finance_account: this.financeAccount,
      receiver_type: this.receiverType.toLocaleLowerCase()
    });

    if (error) {
      this.error = error;
    }

    if (!this.letter) return;

    if (this.conservatorship) {
      this.letter.conservatorship_id = this.conservatorship;
    }

    this.letter.receiver_type = this.receiverType;
  }
}
